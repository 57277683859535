import axios from '@axios'

export default {
    namespaced: true,
    state: {
        memberOptions: [],
        selectedMembers: [],
    },
    getters: {},
    mutations: {
        SET_SELECTED_EVENTS(state, val) {
            state.selectedMembers = val
        },
    },
    actions: {
        fetchItems(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/announcements', {
                        params: queryParams,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchItemsUser(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/announcements/by-user', {
                        params: queryParams,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        previewItem(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/announcements/view/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        previewItemUser(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/announcements/by-user/view/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchItem(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/announcements/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addItem(ctx, dataInfo) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/announcements', dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        editItem(ctx, dataInfo) {
            return new Promise((resolve, reject) => {
                axios
                    .put('/announcements', dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        removeItem(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/announcements/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        cancelItem(ctx, dataInfo) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/announcements/cancel', dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        shareItem(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/announcements/submitForApproval/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        reportItem(ctx, dataInfo) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/announcements/report', dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        reportShareItem(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/announcements/report-share/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addDocument(ctx, {announcementId, dataInfo}) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/announcements/document-add/${announcementId}`, dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        markSeen(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`/announcements/by-user/mark-as-seen/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    },
}

<template>
  <b-modal
      no-close-on-backdrop
      centered
      hide-header-close
      ok-variant="dark"
      title="Kayıt Bilgileri"
      modal-class="modal-dark"
      v-model="isPreviewModal"
      size="lg">

    <b-tabs pills>
      <b-tab active>
        <template #title>
          <feather-icon icon="CastIcon"/>
          <span>Duyuru Bilgileri</span>
        </template>
        <table class="mt-2 mt-xl-0 w-100">
          <tr class="border-bottom">
            <th class="pb-50"><span class="font-weight-bold">Başlık</span></th>
            <td class="pb-50">{{ previewData.title }}</td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Kategori</span></th>
            <td class="py-50">{{ previewData.announcementCategoryName }}</td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Öncelik</span></th>
            <td class="py-50">{{ previewData.priorityNumber }}</td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Başlangıç Zamanı</span></th>
            <td class="py-50">
              <date-time-column-table :data="previewData.startDate"></date-time-column-table>
            </td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Bitiş Zamanı</span></th>
            <td class="py-50">
              <date-time-column-table :data="previewData.endDate"></date-time-column-table>
            </td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Özel Duyuru</span></th>
            <td class="py-50">
              <boolean-column-table :data="previewData.isPrivate"></boolean-column-table>
            </td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Duyuru İçeriği</span></th>
            <td class="py-50">{{ previewData.description }}</td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Durum</span></th>
            <td class="py-50">
              <active-column-table :data="previewData.isActive"></active-column-table>
            </td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Oluşturma Zamanı</span></th>
            <td class="py-50">
              <date-time-column-table :data="previewData.createdOn"></date-time-column-table>
            </td>
          </tr>
          <tr>
            <th class="py-50"><span class="font-weight-bold">Değiştirme Zamanı</span></th>
            <td class="py-50">
              <date-time-column-table :data="previewData.modifiedOn"></date-time-column-table>
            </td>
          </tr>
        </table>
      </b-tab>
      <b-tab v-if="previewData && previewData.isPrivate">
        <template #title>
          <feather-icon icon="CastIcon"/>
          <span>Hedef Bilgileri</span>
        </template>
        <b-card no-body class="border">
          <b-table
              :items="previewData.targets"
              :fields="targetFields"
              class="mb-0"
              striped responsive bordered hover>
          </b-table>
        </b-card>
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="FileIcon"/>
          <span>Döküman Bilgileri</span>
        </template>
        <b-card no-body class="border mt-1">
          <b-table
              :items="previewData.documents"
              :fields="documentFields"
              class="mb-0"
              striped responsive bordered hover>

            <template #cell(filePath)="data">
              <div class="text-nowrap">
                <feather-icon :id="`row-${data.item.id}-prev`" icon="EyeIcon" size="16" @click="previewFile(data.value)" class="mx-1"/>
              </div>
            </template>

            <template #cell(createdOn)="data">
              <date-time-column-table :data="data.value"></date-time-column-table>
            </template>

          </b-table>
        </b-card>
      </b-tab>
    </b-tabs>

    <template #modal-footer="">
      <b-row class="d-flex justify-content-end align-items-center">
        <b-button class="mr-1" variant="outline-secondary" @click="$emit('update:is-preview-modal', false)">
          <feather-icon icon="XCircleIcon" size="16"/>
          <span class="align-middle" role="button"> Kapat</span>
        </b-button>
      </b-row>
    </template>

    <Overlay :busy="busy"></Overlay>
  </b-modal>
</template>

<script>
import {ref, watch} from '@vue/composition-api'
import {BButton, BCard, BFormCheckbox, BModal, BRow, BTab, BTable, BTabs} from "bootstrap-vue";
import {useToast} from "vue-toastification/composition";
import Overlay from "@/components/Overlay.vue";
import store from "@/store";
import {toastMessage} from "@core/utils/utils";
import {recordStatusVariant, recordStatusFormat, getApiFile} from "@core/utils/filter"
import {ActiveColumnTable, DateTimeColumnTable} from "@/components/Table";
import BooleanColumnTable from "@/components/Table/BooleanColumnTable.vue";

export default {
  components: {
    BTabs, BTab, BFormCheckbox, BTable, BCard,
    BooleanColumnTable,
    DateTimeColumnTable,
    ActiveColumnTable,
    Overlay,

    BRow,
    BButton,
    BModal
  },
  model: [
    {
      prop: 'isPreviewModal',
      event: 'update:is-preview-modal',
    },
    {
      prop: 'dataRecord',
      event: 'update:data-record',
    }
  ],
  props: {
    isPreviewModal: {
      type: Boolean,
      required: true,
    },
    dataRecord: {
      type: Object,
      required: true,
    },
  },
  setup(props, {emit}) {
    const toast = useToast()
    const busy = ref(false)
    const previewData = ref({});

    watch(() => props.dataRecord, (dataValue) => {
      if (dataValue.id !== undefined) {
        busy.value = true
        store.dispatch('store/previewItem', {id: dataValue.id}).then(response => {
          previewData.value = response.data.data
        }).catch(error => {
          toastMessage(toast, 'danger', error.response.data.message)
          if (error.response.status === 403) {
            emit('update:is-preview-modal', false)
            emit('update:data-record', {})
          }
        }).finally(message => {
          busy.value = false
        })
      }
    })

    const targetFields = [
      {key: 'announcementTargetTypeName', label: 'Hedef Tipi'},
      {key: 'targetName', label: 'Hedef'}
    ]

    const documentFields = [
      {key: 'name', label: 'Dosya Adı', sortable: false},
      {key: 'createdUserName', label: 'Ekleyen', sortable: false},
      {key: 'createdOn', label: 'Tarih', sortable: true},
      {key: 'filePath', label: 'Görüntüle', sortable: false},
    ]

    const previewFile = (filePath) => {
      window.open(getApiFile(filePath), '_blank');
    }

    return {
      busy,
      previewData,
      targetFields,
      documentFields,

      recordStatusFormat,
      recordStatusVariant,
      previewFile
    }
  },
}
</script>
